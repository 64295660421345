.user-wrapper{
    /* background-color: aquamarine; */
    position: relative;
    width: 80%;
    left: 16rem;
    top: 1rem;
   
}
@media print {
    body {
      visibility: hidden;
    }
    #InvoicePdf {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
    }
  }


table {
    width: 98%;
    border-collapse: collapse;
    font-weight: 600;margin-top: 1rem   ;
    
}
table tr th{
    text-align: left;
   
}

.header_fixed {
    max-height: 100vh;
    width: 100%;
    overflow: auto;
   
}

.header_fixed thead th {
    position: sticky;
    top: 0;
    color: #e6e7e8;
    font-size: 15px;
   
}
.th-first{
   
   
    padding: 1.3rem 1rem ;
}
th{
   
}
th,
td {
    /* border-bottom: 1px solid #dddddd; */
    padding: 10px 20px;
    border-collapse: collapse;
    transition: 50ms ease-in-out;
   
}
.table-name-create-user{
    display: flex;
    padding: .45rem ;
    /* border-radius: 5px ; */
    
    justify-content: flex-start;
    font-family: 'Nunito', sans-serif;
    padding-left: 1rem;
    width: 98%;
    font-size: .75rem;
    
    padding: .5rem 1rem;
    justify-content: space-between;
    
}
.table-name-create-user h1{
font-size: 1.3rem;
}
.table-name-create-user button{
    padding: .5rem .9rem;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    
    transition: 20ms ease-in-out;
 
}
.table-name-create-user button:hover{
}
 tbody tr{
}
thead tr{
   
}

td img {
    height: 60px;
    width: 60px;
    border-radius: 100%;
    border: 5px solid #e6e7e8;
}
.edit-delete-btn{
    /* background-color: #1f3b58; */
    display: flex;
    align-items: center;
    
}
.edit-delete-btn button{
    font-size: 15px;
    border: none;
    padding: 7px 20px;
    border-radius: 10px;
}
.edit-delete-btn .delete{
    margin-left: 5px;
    transition: 100ms;
    cursor: pointer;
}
.edit-delete-btn .edit{
   transition: 100ms ease;
    cursor: pointer;
}
.edit-delete-btn .edit:hover{

}
.edit-delete-btn .delete:hover{


}
tr:hover td {
    
    cursor: pointer;
   
    /* border-radius: 10px; */
   
}

td button {
   
}

::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.014);
}

::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}