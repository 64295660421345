@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "DM Sans", sans-serif !important;
    font-feature-settings: "kern" !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.5px;
  }
}

:root {
  --color-primary: rgb(0, 82, 204);
  --color-secondary: #789012;
}
.sidebar-Wrapper {
  background-image: radial-gradient(
    circle at bottom right,
    rgb(52, 33, 141) 0%,
    rgb(52, 33, 141) 20%,
    rgb(52, 50, 168) 20%,
    rgb(52, 50, 168) 40%,
    rgb(52, 68, 195) 40%,
    rgb(52, 68, 195) 60%,
    rgb(52, 85, 221) 60%,
    rgb(52, 85, 221) 80%,
    rgb(0, 82, 204) 80%,
    rgb(0, 82, 204) 100%
  );
}
.sihn-in-button {
  background-image: radial-gradient(
      circle at 85% 1%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 96%,
      transparent 96%,
      transparent 100%
    ),
    radial-gradient(
      circle at 14% 15%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 1%,
      transparent 1%,
      transparent 100%
    ),
    radial-gradient(
      circle at 60% 90%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 20%,
      transparent 20%,
      transparent 100%
    ),
    radial-gradient(
      circle at 79% 7%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 78%,
      transparent 78%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 65%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 52%,
      transparent 52%,
      transparent 100%
    ),
    linear-gradient(135deg, rgb(1, 27, 255), rgb(98, 72, 255));
}
.sign-in-header {
  background-image: radial-gradient(
      circle at 85% 1%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 96%,
      transparent 96%,
      transparent 100%
    ),
    radial-gradient(
      circle at 14% 15%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 1%,
      transparent 1%,
      transparent 100%
    ),
    radial-gradient(
      circle at 60% 90%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 20%,
      transparent 20%,
      transparent 100%
    ),
    radial-gradient(
      circle at 79% 7%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 78%,
      transparent 78%,
      transparent 100%
    ),
    radial-gradient(
      circle at 55% 65%,
      rgba(44, 44, 44, 0.05) 0%,
      rgba(44, 44, 44, 0.05) 52%,
      transparent 52%,
      transparent 100%
    ),
    linear-gradient(135deg, rgb(1, 27, 255), rgb(98, 72, 255));
}
.sign-in-wrapper {
  background-image: linear-gradient(
      22.5deg,
      rgba(242, 242, 242, 0.03) 0%,
      rgba(242, 242, 242, 0.03) 16%,
      rgba(81, 81, 81, 0.03) 16%,
      rgba(81, 81, 81, 0.03) 26%,
      rgba(99, 99, 99, 0.03) 26%,
      rgba(99, 99, 99, 0.03) 73%,
      rgba(43, 43, 43, 0.03) 73%,
      rgba(43, 43, 43, 0.03) 84%,
      rgba(213, 213, 213, 0.03) 84%,
      rgba(213, 213, 213, 0.03) 85%,
      rgba(125, 125, 125, 0.03) 85%,
      rgba(125, 125, 125, 0.03) 100%
    ),
    linear-gradient(
      22.5deg,
      rgba(25, 25, 25, 0.03) 0%,
      rgba(25, 25, 25, 0.03) 54%,
      rgba(144, 144, 144, 0.03) 54%,
      rgba(144, 144, 144, 0.03) 60%,
      rgba(204, 204, 204, 0.03) 60%,
      rgba(204, 204, 204, 0.03) 76%,
      rgba(37, 37, 37, 0.03) 76%,
      rgba(37, 37, 37, 0.03) 78%,
      rgba(115, 115, 115, 0.03) 78%,
      rgba(115, 115, 115, 0.03) 91%,
      rgba(63, 63, 63, 0.03) 91%,
      rgba(63, 63, 63, 0.03) 100%
    ),
    linear-gradient(
      157.5deg,
      rgba(71, 71, 71, 0.03) 0%,
      rgba(71, 71, 71, 0.03) 6%,
      rgba(75, 75, 75, 0.03) 6%,
      rgba(75, 75, 75, 0.03) 15%,
      rgba(131, 131, 131, 0.03) 15%,
      rgba(131, 131, 131, 0.03) 18%,
      rgba(110, 110, 110, 0.03) 18%,
      rgba(110, 110, 110, 0.03) 37%,
      rgba(215, 215, 215, 0.03) 37%,
      rgba(215, 215, 215, 0.03) 62%,
      rgba(5, 5, 5, 0.03) 62%,
      rgba(5, 5, 5, 0.03) 100%
    ),
    linear-gradient(90deg, #ffffff, #ffffff);
}
.sidebar-WrapperDark {
  background-image: radial-gradient(
    circle at bottom right,
    rgb(17, 28, 68) 0%,
    rgb(17, 28, 68) 20%,
    rgb(22, 21, 83) 20%,
    rgb(22, 21, 83) 40%,
    rgb(17, 28, 68) 40%,
    rgb(17, 28, 68) 60%,
    rgb(12, 27, 98) 60%,
    rgb(12, 27, 98) 80%,
    rgb(17, 28, 68) 80%,
    rgb(17, 28, 68) 100%
  ) !important;
}
input.defaultCheckbox::before {
  content: url(../src/assets/svg/checked.svg);
  color: white;
  opacity: 0;
  height: 16px;
  width: 16px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
  opacity: 1;
}
.tablebody-costom {
  /* background-color: aquamarine; */
  height: 200px !important;
}
.tableall-costom {
  height: 70vh !important;
  overflow-y: scroll !important;
}
.Toastify__toast {
  width: 380px;
  height: 80px;
}
.status-approved-btn {
  background-color: rgb(3, 189, 31);
  color: rgb(255, 255, 255);
  font-size: 12px;
  padding: 0 20px;
  width: 100px;
  border-radius: 50px;
}
.status-pending-btn {
  background-color: rgb(243, 209, 140);
  font-size: 12px;
  padding: 0 20px;
  width: 100px;
  border-radius: 50px;
}
.layout-background {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(54, 82, 185, 1) 94%
  );
}
.nav-top-line {
  border-radius: 50px 50px 0 0;
}

.add-booking-header {
  z-index: 1;
}

/* react input design */
.css-qbdosj-Input {
  padding: 0.6rem 0 !important;
}
.css-13cymwt-control {
  box-shadow: 0 2px 8px -2px rgb(101, 150, 255);
}

.dropdown-heading {
  padding: 25px 5px !important;
  box-shadow: 0 2px 8px -2px rgb(101, 150, 255);
}
/* react input design */
.ck-editor__editable_inline {
  min-height: 400px;
}
@media (max-width: 1000px) {
  .Toastify__toast {
    width: 100%;
    height: auto;
  }
}
:root {
  --white: #fff;
  --black: #000;
  --dark: #1e1e1e;
  --gray: rgba(1, 1, 1, 0.6);
  --lite: rgba(255, 255, 255, 0.6);
  --primary: #002347;
  --secondary: #fdc632;
}

.flex-content {
  @apply w-full relative;
}
.padding_1x {
  @apply p-4;
}
.padding_2x {
  @apply p-8;
}
.padding_3x {
  @apply p-12;
}
.padding_4x {
  @apply p-16;
}
.btn {
  @apply text-center font-medium uppercase px-8 py-[0.8rem] rounded-[5px];
}
.btn_1 {
  /* @apply border border-[color:var(--primary)] bg-[color:var(--primary)] text-[color:var(--secondary)] border-solid hover:bg-transparent hover:text-[color:var(--primary)]; */
}
.btn_2 {
  @apply border border-[color:var(--secondary)] bg-[color:var(--secondary)] text-[color:var(--primary)] border-solid hover:border hover:border-[color:var(--primary)] hover:bg-[color:var(--primary)] hover:text-[color:var(--secondary)] hover:border-solid;
}
@media (max-width: 920px) {
  .flex {
    @apply flex-wrap;
  }
  .padding_1x,
  .padding_2x,
  .padding_3x,
  .padding_4x {
    @apply p-4;
  }
  .btn {
    @apply px-4 py-2;
  }
  a,
  p {
    @apply text-xs;
  }
}

